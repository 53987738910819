<template>
  <div class="container-perfil">
        <!-- -->
      <section class="container-fluid">
        <section class="container">
          <div class="row">
            <div class="col"><br>
            <h1 style="color:#400041;" class="bold">Olá, {{this.$store.state.usuario.nome}}</h1><br>
            </div>
            </div>
        </section>

        <div class="row">

          <div class="col-sm-7 fundo-roxo-perfil quadro">
            <br>
            <div class="espacamento">
               <h3 class="txt-white bold">Imagem do Perfil</h3>
  <div class="row">
            <div class="col"> 
              <vue-anka-cropper
              @cropper-saved="onFileSelected"
              :options="{showPreview: false,dropareaMessage: 'Arraste ou clique aqui para subir uma imagem.',skin: 'dark',selectButtonLabel:'SELECIONAR FOTO'}"
              ></vue-anka-cropper>
            </div>
            </div>

                <div class="row">
                  <div class="col-12 col-sm-7">
  <br>  <br>
                  
                     <img v-if="usuario.USU_FOTO != ''" :src="usuario.USU_FOTO" width="250" height="auto" ref="imgPerfil" class="img-fluid" />
                      <img v-if="usuario.USU_FOTO == null || usuario.USU_FOTO == ''" src="/img/no-photo.jpg" ref="imgPerfil" class="img-fluid" />

                 </div>
                 <div class="col-12 col-sm-4">
                     <button  v-if="showLoading == false" type="button" role="button" @click="deletarFoto" class="bt-atualizar-cadastro btn-primary bold mt-5" style="display:block !important;">EXCLUIR</button>
                    <!-- <div class="form-group">
                      <label class="txt-white bold">Selecionar Foto: (200px x 200px)</label>
                     <input type="file" name="imagem" ref="foto" class="form-control input-cadastro" @change="onFileSelected" />
                     </div>-->
                 </div> 
               </div>
        
        <br>
 <h3 class="txt-white bold">Dados Cadastrais</h3>
              <!-- -->
                <div class="form-group">
                    <input class="form-control input-cadastro" name="nome" placeholder="Nome" type="text" v-model="nome"/>
                </div>   

                 <div class="form-group">
                    <input class="form-control input-cadastro" name="data" placeholder="Data Nascimento" type="text" v-mask="'##/##/####'" v-model="data"/>
                </div>  
                
                <div class="form-group">
                    <input class="form-control input-cadastro" name="cpf" placeholder="CPF" type="text" v-mask="'###########'" v-model="cpf"/>
                </div>  

                 <div class="form-group d-none">
                    <select type="text" class="form-control input-cadastro" name="sexo" id="sexo" v-model="genero">
                       <option value="">Gênero</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Outro">Outro</option>
                    
                    </select>
                </div>  

                 <div class="form-group">
                    <input v-on:keyup="buscarCep" class="form-control input-cadastro" name="cep" placeholder="CEP" type="text" v-mask="'#####-###'" v-model="cep"/>
                </div>  

                <div class="form-group">
                    <input class="form-control input-cadastro" name="cidade" placeholder="Cidade" type="text" v-model="cidade"/>
                </div> 

                  <div class="form-group">
                    <select type="text" class="form-control input-cadastro" name="estado" v-model="estado">
                       <option value="">Estado</option>
                       <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    
                    </select>
                </div>  


                 <div class="form-group d-none">
                    <select type="text" class="form-control input-cadastro" name="nivel" id="nivel" v-model="nivel">
                       <option value="">Nível de Pratica</option>
                        <option value="Iniciante">Iniciante</option>
                        <option value="Intermediário">Intermediário</option>
                        <option value="Avançado">Avançado</option>
                        <option value="Expert">Expert</option>
                    
                    </select>
                </div>  
<br>
                <h5 class="txt-white">Sua História</h5>

                  <div class="form-group">
                    <textarea class="form-control input-cadastro" name="historia" maxlength="1000" placeholder="Conte para gente um pouco sobre você e o que espera da Tarimba na Cozinha" type="text" v-model="historia"></textarea>
                </div>   

                <br>

                 <button  v-if="showLoading == false" type="button" role="button" @click="validarCampos" class="bt-atualizar-cadastro btn-primary bold" style="display:block !important;">SALVAR ALTERAÇÕES</button>
                  <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>

              <!-- -->

             
            </div>
          </div><!-- col -->

          <div class="col-sm-5 fundo-roxo2 quadro px-0 py-0">
             <div class="espacamento">
            <br>
              <h3 class="txt-white bold">Dados de Acesso</h3><br>
               <p class="txt-cadastro">O acesso sempre será através do seu e-mail:</p><br>

               <div class="form-group">
                    <input class="form-control input-cadastro" name="email" placeholder="E-mail" type="email" v-model="email"/>
                </div>  

                <div class="form-group">
                    <input class="form-control input-cadastro" name="emailConfirma" placeholder="Confirmar E-mail" type="email" v-model="emailConfirma"/>
                </div>  
<br>
                <p class="txt-cadastro">E da senha que você colocar abaixo:</p><br>

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senha" placeholder="Senha" type="password" v-model="senha"/>
                </div> 

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senhaConfirma" placeholder="Confirmar Senha" type="password" v-model="senhaConfirma"/>
                </div> 

                 <button  v-if="showLoading == false" type="button" role="button" @click="validarCamposAcesso" class="bt-atualizar-cadastro btn-primary bold" style="display:block !important;">SALVAR DADOS</button>
                  <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
<br>
           </div> 


          <div class="fundo-roxo2 quadro mx-0">
              <div class="espacamento"><br>
              <h3 class="txt-white bold">Minha Conta</h3>
                <p class="txt-white bold">Informações referente ao meu cadastro:</p>
                <ul class="txt-white bold"><br>
                  <li v-if="usuario.USU_DATA != null" class="bold">Membro desde: {{ usuario.USU_DATA }}.</li>
                  <br>
                 <!-- <li v-if="usuario.USU_RENOVACAO != null  && usuario.USU_PERMISSAO != 'Geral' 
                  && usuario.USU_PERMISSAO != 'Personalizado'" class="bold">Data da renovação: {{ usuario.USU_RENOVACAO }}.</li>
                 <br>
                  <li v-if="usuario.USU_EXPIRACAO != null && usuario.USU_PERMISSAO != 'Geral' 
                  && usuario.USU_PERMISSAO != 'Personalizado' && usuario.USU_PERMISSAO != 'Permanente'" class="bold">Expira em: {{ usuario.USU_EXPIRACAO }}.</li>-->
                  
                   <li v-if="usuario.USU_PERMISSAO != null" class="bold">Tipo da conta: {{ usuario.USU_PERMISSAO }}.</li> 
                    <br> 
                   <!-- <router-link to="/cadastro" class="bt-atualizar-cadastro btn-primary bold btn">IR PARA O PAGAMENTO</router-link> -->
                     
                </ul>
<br>
               
           
             <br>
              </div>
          </div>


          </div><!-- col -->
        </div>

        

      </section>



      <div class="modal" id="modal-validacao" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>

      <!-- -->
  </div>
</template>

<style>
.ankaCropper.dark .ankaCropper__saveButton, .ankaCropper.dark .ankaCropper__selectButton {
    background: transparent !important;
    margin-top:-5px;
}
.ankaCropper__navButton, .ankaCropper__saveButton {
    
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ankaCropper.dark .ankaCropper__navButton {
    background: none !important;
}


.input-cadastro::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFF !important;
  opacity: 1; /* Firefox */
}

.input-cadastro:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFF !important;
}

.input-cadastro::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFF !important;
}

.input-cadastro{color: #FFF !important;border-color: #FFF !important;background:none !important;}
.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo-perfil{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
.txt-cadastro{color:#FFF;font-size:1.15em;}
.bt-atualizar-cadastro {
   background-color: #FFF;
    color: #000;
    min-width:100% !important;
    display:block !important;
    padding:10px 0;
    border:none !important;
}
.bt-atualizar-cadastro:hover{background-color: #F18800 !important;color:#FFF;}
</style>

<script>
import servidor from '@/servidor'
import vueAnkaCropper from 'vue-anka-cropper'

export default {
  name: 'Perfil',
  metaInfo: {
    title: 'Perfil - Tarimba na cozinha'
  },
  components: {vueAnkaCropper},
  data(){
    return {
      showLoading:false,
      semResultados:false,
      nome:'',
      data:'',
      cpf:'',
      genero:'',
      cep:'',
      cidade:'',
      estado:'',
      nivel:'',
      historia:'',
      email:'',
      emailConfirma:'',
      senha:'',
      senhaConfirma:'',
      gratuito:null,
      termos:null,
      texto:'',
      acao:false,
      usuario:{},
       selectedFile:null,
       imagelabel:"Arraste ou clique aqui para subir uma imagem de perfil",
      
    }
  },
  methods:{
     deletarFoto(){
       
       
        let fm = new FormData();
          fm.append('id',this.$store.state.usuario.id);

          fetch(servidor+'verificacao/deletarFoto/'+this.$store.state.usuario.id,{method:"POST", body:fm})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
                this.usuario.USU_FOTO = "";
                this.abrirModal("Imagem deletada com sucesso!");
            }
          }).catch(this.showLoading = false);
   },
     setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    buscarCep(){
      if(this.cep.length == 9){
         fetch('https://viacep.com.br/ws/'+this.cep+'/json/')
          .then((response) => response.json())
          .then((data) => {

            if(data.erro != null){
                this.abrirModal("CEP informado é inválido!");
                this.cep = "";
            }else{
               this.cidade = data.localidade;
               this.estado = data.uf;
            }
          });
      }
      
    },
    onFileSelected(event){
       console.log(event);
      //this.showLoading = true;
      // this.selectedFile = event.target.files[0];
       
        let fm = new FormData();
          fm.append('image',event.croppedFile,'imagem.jpg');

          fetch(servidor+'verificacao/foto/'+this.$store.state.usuario.id,{method:"POST", body:fm})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
                this.usuario.USU_FOTO = data.foto;
                this.abrirModal("Imagem alterada com sucesso!");
            }
          }).catch(this.showLoading = false);
   },
   validarCampos(){
    
     if(this.nome == null || this.nome.length < 4){this.abrirModal("Nome deve conter pelo menos 4 caracteres!");return;}
      if(this.data == null || this.data.length < 10){this.abrirModal("Data de nascimento incorreta!");return;}
      //if(this.genero.length < 1){this.abrirModal("Campo gênero é obrigatório!");return;}
      if(this.cidade == null || this.cidade.length < 3){this.abrirModal("Cidade é obrigatória e deve conter pelo menos 3 caracteres!");return;}
      if(this.estado == null || this.estado.length < 1){this.abrirModal("Estado é obrigatório!");return;}
      if(this.cep == null || this.cep.length < 9){this.abrirModal("Cep é obrigatório e deve conter 9 caracteres!");return;}
      if(this.cpf == null || this.cpf.length < 11){this.abrirModal("CPF é obrigatório e deve conter 11 caracteres!");return;}
      if(this.isValidCPF(this.cpf) == false){this.abrirModal("CPF Inválido!");return;}
      //if(this.nivel.length < 1){this.abrirModal("Nível de pratica é obrigatório!");return;}
      this.acao = false;
      this.enviar();
   },
    validarCamposAcesso(){
    
        if(this.email.length < 5){this.abrirModal("E-mail inválido!");return;}
        if(this.email != this.emailConfirma){this.abrirModal("Confirmação de E-mail não combina!");return;}
        if(this.senha.length < 8){this.abrirModal("Senha deve conter pelo menos 8 caracteres!");return;}
        if(this.senha != this.senhaConfirma){this.abrirModal("Confirmação de Senha não combina!");return;}
        this.acao = true;
      this.enviar();
   },

   abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-validacao').modal('show');
   },
   isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999" 
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var j = 1; j <= 10; j++) 
        soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
    },
    enviar(){
      this.showLoading = true;
      let dados = new FormData();
      dados.append('nome',this.nome);
      dados.append('email',this.email);
      dados.append('senha',this.senha);
      dados.append('genero',this.genero);
      dados.append('cep',this.cep);
      dados.append('cpf',this.cpf);
      dados.append('nivel',this.nivel);
      dados.append('data',this.data);
      dados.append('cidade',this.cidade);
      dados.append('estado',this.estado);
      dados.append('historia',this.historia);
      dados.append('acao',this.acao);

      fetch(servidor+'verificacao/update/'+this.$store.state.usuario.id,{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
             
                this.$store.commit('logarUsuario',data);
                this.setCookie('usuario',JSON.stringify(this.logado), 1);
                this.abrirModal("Alterações realizadas com sucesso!");
            }
          }).catch(this.showLoading = false);
    },

    getInfo(){
       this.showLoading = true;
       fetch(servidor+'verificacao/getUsuario/'+this.$store.state.usuario.id)
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
              this.usuario = data;
              this.preencher(data);
            }
          }).catch(this.showLoading = false);
    },
    preencher(dados){
      this.nome = dados.USU_NOME;
      this.email = dados.USU_EMAIL;
      this.emailConfirma = dados.USU_EMAIL;
      this.data = dados.USU_NASCIMENTO;
      this.cpf = dados.USU_CPF;
      this.cep = dados.USU_CEP;
      this.genero = dados.USU_SEXO;
      this.cidade = dados.USU_CIDADE;
      this.estado = dados.USU_UF;
      this.nivel = dados.USU_NIVEL;
      this.historia = dados.USU_HISTORIA;
      this.senha = dados.USU_SENHA;
      this.senhaConfirma = dados.USU_SENHA;
    }
  },
  computed:{
    logado(){
      return this.$store.state.usuario;
    }
  },
  
  mounted(){
    if(this.$store.state.completarCadastro){
      this.abrirModal('É nescessário completar seu cadastro para efetuar seu pagamento! CEP, CIDADE, ESTADO E DATA NASCIMENTO obrigatórios.');
      this.$store.commit('showAlertaCadastro',false);
    }

    if(this.$store.state.alertaCadastro){
      this.abrirModal('Obrigado por se cadastrar. A partir de agora você faz parte da Tarimba na Cozinha e possui acesso a todas as nossas receitas. Bem vindo!');
      this.$store.commit('showAlertaCadastroFinalizado',false);
    }

     this.getInfo();

  }
}
</script>